var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header", { attrs: { hasLine: true } }),
        _c(
          "div",
          {
            staticClass: "service",
            staticStyle: { padding: "0", "margin-top": "77px" },
          },
          [
            _c("div", { staticClass: "frist-m3-area" }, [
              _c("div", { staticClass: "frist-m3-area frist-m3-area-top" }, [
                _c("img", {
                  staticClass: "banner-img",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/detail/the5/2-1.jpg",
                  },
                }),
                _c("div", { staticClass: "frist-m3-area-sub-title" }, [
                  _c("div", { staticStyle: { "margin-bottom": "6px" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("car_list.the5.suitable"))),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.the5.style")))]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-m3-area-title" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("car_list.the5.slogan"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-m3-area-buy-area" }, [
                  _c(
                    "div",
                    {
                      staticClass: "frist-m3-area-buy-button",
                      on: {
                        click: function ($event) {
                          return _vm.goToBuy()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("car_list.buy_now")) + " ")]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "frist-m3-area frist-m3-area-show",
                  staticStyle: {
                    "padding-top": "0",
                    "background-color": "#0c0c0c",
                  },
                },
                [
                  _c("img", {
                    staticClass: "frist-m3-area-top-bottom-more",
                    attrs: {
                      src: "https://www.achencybe.com/img/pc/other/LearnMore.png",
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "frist-m3-area-show-area-div" }, [
                    _c(
                      "div",
                      { staticClass: "frist-m3-area-show-area-div-top-box" },
                      [
                        _c("div", { staticClass: "logo-box" }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: "https://www.achencybe.com/img/pc/logo.png",
                            },
                          }),
                          _c("div", { staticClass: "line" }),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "18px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.the5.show_page.technology_1")
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.the5.show_page.technology_2")
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.the5.show_page.technology_3")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: { width: "60%", "margin-bottom": "100px" },
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/detail/the5/2-3-1.png",
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "frist-m3-area frist-m3-area-goodlist" },
                [
                  _c(
                    "div",
                    { staticClass: "features-box" },
                    [
                      _c("features", {
                        attrs: {
                          title: "car_list.the5.show_page.technology_5",
                          "sub-title": _vm.subTitle,
                          isThe3: true,
                          isHidden: true,
                          language: this.$i18n.locale,
                          list: _vm.list,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "font-size-padding50 font-size-12",
                      staticStyle: { width: "100%", "font-weight": "100" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("car_list.care_plus_remark_1"))
                      ),
                      _c("br"),
                    ]
                  ),
                  _c("div", { staticClass: "banner-text" }, [
                    _c("div", { staticStyle: { "font-size": "55px" } }, [
                      _vm._v(_vm._s(_vm.$t("car_list.the5.show_page.slogan"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "max-width": "900px",
                          margin: "0 auto",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("car_list.the5.show_page.sub_slogan")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "carousel-box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "left",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.carousel.prev()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://www.achencybe.com/img/pc/detail/challenger/left.png",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-carousel",
                        {
                          ref: "carousel",
                          attrs: {
                            interval: 5000,
                            arrow: "never",
                            height: _vm.bannerHeight,
                          },
                        },
                        _vm._l(5, function (item) {
                          return _c("el-carousel-item", { key: item }, [
                            _c("img", {
                              attrs: {
                                src:
                                  "https://www.achencybe.com/img/pc/detail/the5/2-5-" +
                                  item +
                                  ".jpg",
                                alt: "",
                              },
                            }),
                          ])
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.carousel.next()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://www.achencybe.com/img/pc/detail/challenger/right.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#0c0c0c",
                        "padding-top": "150px",
                      },
                    },
                    [
                      _c("introduce", {
                        attrs: {
                          imgRight: "",
                          introduceList: _vm.introduceList,
                          direction: "right",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-width": "1380px",
                            width: "100%",
                            "margin-top": "200px",
                            margin: "0 auto",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "54px",
                                "text-align": "left",
                                width: "1200px",
                                margin: "0 auto",
                              },
                            },
                            [_vm._v(" DESIGNED BY ACHEN&CYBE STUDIO ")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "54px",
                                "text-align": "left",
                                width: "1200px",
                                margin: "-18px auto 0",
                              },
                            },
                            [_vm._v(" 独有原创设计 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "text-align": "left",
                                width: "1200px",
                                margin: "0 auto",
                              },
                            },
                            [_vm._v(" 由Achen&Cybe Studio提供 ")]
                          ),
                          _c("img", {
                            staticClass: "frist-m3-area-goodlist-image-area",
                            attrs: {
                              src: "https://www.achencybe.com/img/pc/detail/the5/2-7.png",
                            },
                          }),
                        ]
                      ),
                      _c("detail-footer", {
                        attrs: {
                          title: "BMW THE 5/M5",
                          img: "https://www.achencybe.com/img/pc/detail/the5/2-9.png",
                        },
                        on: {
                          buy: function ($event) {
                            return _vm.goToBuy()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticStyle: { clear: "both" } }),
          ]
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }